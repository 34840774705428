@charset "UTF-8";

@font-face {
    font-family: "klouds-font";
    src:
            url("./../../assets/fonts/klouds-font.woff") format("woff"),
            url("./../../assets/fonts/klouds-font.ttf") format("truetype"),
            url("./../../assets/fonts/klouds-font.svg#klouds-font") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}


[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'klouds-font', serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-klouds-logo:before {
    content: "\e9a6";
}
